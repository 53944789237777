<template>
  <div class="statistic">
    <v-row style="">
      <v-col cols="6">
        <p style="margin-top:30px;font-size:32px;">
          任務管理
        </p>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="$store.state.tab"
        >
          <v-tabs-slider color="#4DBCB8"></v-tabs-slider>

          <v-tab
            v-for="item in tabItems"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="$store.state.tab">
          <v-tab-item
            v-for="item in tabItems"
            :key="item"
          >
            <v-row>
              <v-col cols="6">
                <nested-draggable
                style="height:540px;overflow:scroll;"
                v-if="tabItems[$store.state.tab] === '施作任務'"
                :tasks="$store.state.taskCategories" />
                <nested-draggable
                v-if="tabItems[$store.state.tab] === '路程加給'"
                :tasks="$store.state.trafficCategories" />
                <!-- <draggable
                  v-model="$store.state.testArray"
                  group="people"
                  @start="onStart"
                  @end="onEnd"
                  @change="onChange"
                >
                   <div v-for="element in $store.state.testArray" :key="element.id">
                     {{element.name}}
                     <nested-draggable v-if="element.children" :tasks="element.children" />
                   </div>
                </draggable>
                {{ myArray }} -->
                <!-- <draggable
                  :list="myArray"
                  @start="onStart"
                  @end="onEnd"
                  @change="onChange"
                >
                  <v-treeview
                    activatable
                    :items="myArray"
                  >
                    <template slot="label" slot-scope="{ item }">
                        <div :id="item.id">{{item.name}}</div>
                    </template>
                  </v-treeview>
                </draggable> -->
                <!-- <v-treeview
                  :items="items"
                  activatable
                  @input="edit"
                >
                  <template slot="label" slot-scope="{ item }">
                    <draggable
                      :list="items"
                      @mouseover="over(item)"
                      @mouseleave="leave(item)"
                      @click="edit(item)"
                      style="width:100%"
                    >
                      {{ item.name }}
                      <v-icon
                        style="float:right;"
                        v-show="item.edit"
                        @click="addItem"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                      <v-icon
                        style="float:right;"
                        v-show="item.edit"
                        @click="addItem"
                      >
                        mdi-plus
                      </v-icon>
                    </draggable>
                  </template>
                </v-treeview> -->
              </v-col>
              <v-col cols="1">
                <v-divider
                  style="height:500px;"
                  vertical
                ></v-divider>
              </v-col>
              <v-col cols="5">
                <v-card elevation="0" v-show="taskEdit">
                  <div style="text-align:right;">
                    <v-icon @click="taskEdit = false">
                      mdi-close
                    </v-icon>
                  </div>
                  <v-card-text style="font-size:18px;color:black">
                    <div style="height:440px;overflow:scroll;">
                      <div
                        v-if="tabItems[$store.state.tab] === '施作任務'"
                        style="font-weight:bold;margin-bottom:6px;">
                        任務名稱
                      </div>
                      <div
                        v-if="tabItems[$store.state.tab] === '路程加給'"
                        style="font-weight:bold;margin-bottom:6px;">
                        目的地
                      </div>
                      <v-text-field
                        v-model="$store.state.task.name"
                        outlined
                        dense
                        @change="updateTask"
                      ></v-text-field>
                      <!-- <v-autocomplete
                        v-model="$store.state.task.name"
                        :items="finishedTasks.name"
                        auto-select-first>
                      </v-autocomplete> -->
                      <div
                        v-if="tabItems[$store.state.tab] === '施作任務'"
                        style="font-weight:bold;margin-bottom:6px;"
                      >
                        預計工時
                      </div>
                      <div
                        v-if="tabItems[$store.state.tab] === '路程加給'"
                        style="font-weight:bold;margin-bottom:6px;"
                      >
                        預計路程時數
                      </div>
                      <v-text-field
                        v-model="$store.state.task.planed"
                        outlined
                        type="number"
                        dense
                        @change="updateTask"
                        suffix="分鐘">
                      </v-text-field>
                      <div
                        v-if="tabItems[$store.state.tab] === '施作任務'"
                        style="font-weight:bold;margin-bottom:6px;"
                      >
                        任務積分
                      </div>
                      <div
                        v-if="tabItems[$store.state.tab] === '路程加給'"
                        style="font-weight:bold;margin-bottom:6px;"
                      >
                        路程積分
                      </div>
                      <v-text-field
                        outlined
                        type="number"
                        dense
                        @change="updateTask"
                        v-model="$store.state.task.score">
                      </v-text-field>
                      <div v-if="tabItems[$store.state.tab] === '施作任務'">
                        <div
                          style="font-weight:bold;margin-bottom:6px;">
                          照片
                        </div>
                        <span v-if="$store.state.task.images != undefined">
                          <!-- <v-text-field
                            v-for="(image, index) in $store.state.task.images"
                            v-model="$store.state.task.images[index]"
                            :prefix="`照片${index + 1} :`"
                            :key="image"
                            placeholder="備註"
                            outlined
                            dense
                            @change="updateTask"
                          ></v-text-field> -->
                          <v-text-field
                            v-for="(image, index) in $store.state.task.images"
                            v-model="$store.state.task.images[index]"
                            :prefix="`照片${index + 1} :`"
                            :key="image"
                            placeholder="備註"
                            outlined
                            dense
                            @input="updateTask"
                          ></v-text-field>
                          <div style="margin-top:-20px;">
                            <div
                              @click="$store.state.task.images.pop()"
                              style="cursor:pointer;float:right;">
                                <v-icon color="red">mdi-minus</v-icon>
                            </div>
                            <div
                              @click="$store.state.task.images.push('')"
                              style="cursor:pointer;float:right;">
                                <v-icon color="#4DBCB8">mdi-plus</v-icon>
                            </div>
                          </div>
                        </span>
                        <!-- <v-text-field
                          v-else
                          v-model="$store.state.task.images[0]"
                          outlined
                          dense
                          @change="updateTask"
                        ></v-text-field> -->
                      </div>
                    </div>
                    <div style="width:100%;text-align:center;">
                      <v-btn
                        style="width:250px;font-size:18px;"
                        depressed
                        color="#4DBCB8"
                        dark
                        large
                      >
                        完成
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import draggable from 'vuedraggable';
import api from '../api/task';
import nestedDraggable from '../components/Nested.vue';
// @ is an alias to /src

export default {
  name: 'TaskManage',
  components: {
    // draggable,
    nestedDraggable,
  },
  data: () => ({
    tabItems: ['施作任務', '路程加給'],
    tab: '',
    finishedTaskName: [],
    currentIndex: null,
    currentLayer: null,
    addIcon: false,
    taskEdit: true,
    taskName: '',
    taskPlan: '',
    taskScore: '',
    tasks: [
      {
        id: 1,
        name: '安裝類',
        layer: 0,
        edit: false,
      },
    ],
    items: [
      {
        id: 1,
        name: '安裝類',
        layer: 0,
        edit: false,
        children: [
          {
            id: 2,
            name: '濾水器安裝',
            children: [
              {
                name: '家用氣泡水',
                children: [
                  {
                    name: 'YS-130',
                  }, {
                    name: 'YS-120(CUML35.3)',
                  },
                ],
              }, {
                name: '商用氣泡水 Natura商用',
              }, {
                name: '拆機',
                children: [
                  {
                    name: '家用氣泡水',
                  }, {
                    name: '商用氣泡水',
                  },
                ],
              },
            ],
          }, {
            id: 3,
            name: '家電安裝',
          },
        ],
      }, {
        name: '換濾心',
        layer: 0,
      }, {
        name: '維修類',
        layer: 0,
        children: [
          {
            name: '濾水器維修',
          }, {
            name: '烤箱/蒸烤爐維修',
          }, {
            name: '瓦斯爐/電陶爐(感應爐)維修',
          },
        ],
      },
    ],
  }),
  // mounted() {
  //   this.getTask();
  // },
  computed: {
    finishedTasks() {
      return this.readTaskFromTasks(this.$store.state.taskCategories);
    },
  },
  methods: {
    // getTask() {
    //   api.getTask()
    //     .then(async ({ data }) => {
    //       if (data.success) {
    //         console.log(data);
    //         this.$set(this.$store.state, 'taskCategories', data.tasks);
    //       } else {
    //         console.log('err:', data);
    //       }
    //     });
    // },
    async updateTask() {
      const newData = {};
      if (this.$store.state.tab === 0) {
        newData.tasks = this.$store.state.taskCategories;
      } else if (this.$store.state.tab === 1) {
        newData.traffics = this.$store.state.trafficCategories;
      } else {
        console.log('tab wrong');
      }
      console.log('lalala', newData);
      // await this.loop(newData.tasks);
      await console.log(newData, '??????');
      api.updateTask(newData)
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data);
          } else {
            console.log('err:', data);
          }
        });
    },
    loop(arr) {
      arr.forEach((ele) => {
        // const key = Object.prototype.hasOwnProperty.call(ele, 'id') ? ele.id : ele.time;
        // ele.id = ele.time;
        this.$set(ele, 'id', ele.time);
        if (ele.children.length > 0) {
          this.loop(ele.children);
        }
        // console.log(key);
      });
    },
    readTaskFromTasks(array) {
      const arr = [];
      array.forEach((ele) => {
        if (ele.children.length > 0) {
          console.log('!@!@!@!@!@!@!@', ele);
          this.readTaskFromTasks(ele.children);
        } else {
          this.$store.state.taskList.push(ele);
        }
      });
      return arr;
    },
    onEnd(thing) {
      console.log(thing.to, '--------');
    },
    onStart(thing) {
      console.log(thing, '++++++++');
    },
    onChange({ removed, added, moved }) {
      console.log(removed, added, moved, '????');
    },
    over(content) {
      console.log(content.edit);
      this.$set(content, 'edit', true);
      const equal = (element) => {
        console.log(element, content);
        return element.name === content.name;
      };
      const newIndex = this.items.findIndex(equal);
      this.currentIndex = newIndex;
      this.currentLayer = content.layer;
    },
    leave(content) {
      this.$set(content, 'edit', false);
    },
    addItem() {
      const time = new Date();
      console.log(this.currentIndex, this.currentLayer, '|||||');
      this.items.splice(this.currentIndex + 1, 0, {
        id: time.getTime(),
        time: time.getTime(),
        name: '',
        layer: this.currentLayer,
      });
    },
    edit(some) {
      this.taskEdit = true;
      console.log(some, '++');
    },
  },
};
</script>
