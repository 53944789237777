<template>
  <draggable
    tag="ul"
    :list="tasks"
    :group="{ name: 'g1' }"
    style="width:100%;"
    @change="onChange"
  >
    <li v-for="(el) in tasks" :key="el.name">
      <div
        @mouseover="over(el)"
        @mouseleave="leave(el)"
        @click="select(el)"
        :style="'background-color:' + el.hoverColor"
        style="border-radius: 5px;padding:4px;cursor:pointer;">
        {{el.name}}
        <span style="float:right" v-if="el.editable">
          <v-icon @click="add(el)">
            mdi-plus
          </v-icon>
          <v-icon @click="fireRemove(el)">
            mdi-trash-can-outline
          </v-icon>
        </span>
      </div>
      <nested-draggable v-if="el.children" :tasks="el.children" />
    </li>
    <v-dialog v-model="dialog" width="400">
      <v-card style="background-color:white;">
        <v-row>
          <v-spacer />
          <v-col cols="8">
            <div style="padding:36px 0px;">刪除的項目將無法還原，確認刪除？</div>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="red"
              outlined
              style="margin: 20px 0px;width:90px;height:60px;"
              :loading="removing"
              @click="remove()">
              刪除
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import api from '../api/task';

export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      removing: false,
      wannaRemoveId: null,
      newTaskName: '',
      dialog: false,
      color: 'transparent',
      removeTime: 0,
      findTime: 0,
      findIndexArr: [],
      findObj: {},
      deep: 0,
      count: 0,
      deepArray: [],
      findArray: [],
    };
  },
  components: {
    draggable,
  },
  name: 'nested-draggable',
  methods: {
    // saveTask() {
    //   alert('lalala');
    // },
    over(thing) {
      this.$set(thing, 'editable', true);
      this.$set(thing, 'hoverColor', 'rgba(0,0,0,.1)');
    },
    leave(thing) {
      this.$set(thing, 'editable', false);
      this.$set(thing, 'hoverColor', 'transparent');
    },
    select(thing) {
      console.log(thing, '{{{{{}}}}}');
      this.$set(this.$store.state, 'task', thing);
    },
    add(thing) {
      // this.dialog = true;
      const time = new Date();
      const newThing = {
        // level: thing.level + 1,
        name: '新任務名稱',
        // parents: thing.parents,
        planed: 0,
        score: 1,
        id: time.getTime(),
        time: time.getTime(),
        images: [],
        children: [
        ],
      };
      // newThing.parents.push(thing.name);
      thing.children.push(newThing);
    },
    fireRemove(thing) {
      this.dialog = true;
      this.wannaRemoveId = thing.time;
    },
    async remove() {
      const updateData = {};
      this.removing = true;
      if (this.$store.state.tab === 0) {
        const task = this.$store.state.taskCategories;
        this.removeTime = this.wannaRemoveId;
        await task.filter((e) => e.time !== this.wannaRemoveId);
        await this.$set(this.$store.state, 'taskCategories', task.filter((e) => e.time !== this.wannaRemoveId));
        await this.nestRemove(task, this.removeTime);
        updateData.tasks = await this.$store.state.taskCategories;
      } else {
        const traffic = this.$store.state.trafficCategories;
        this.removeTime = this.wannaRemoveId;
        await traffic.filter((e) => e.time !== this.wannaRemoveId);
        await this.$set(this.$store.state, 'trafficCategories', traffic.filter((e) => e.time !== this.wannaRemoveId));
        await this.nestRemove(traffic, this.removeTime);
        updateData.traffics = await this.$store.state.trafficCategories;
      }
      await api.updateTask(updateData)
        .then(async ({ data }) => {
          if (data.success) {
            this.dialog = false;
            this.wannaRemoveId = null;
            this.removing = false;
            console.log(data);
          } else {
            console.log('err:', data);
          }
        });
    },
    nestRemove(arr) {
      arr.forEach((ele) => {
        this.$set(ele, 'children', ele.children.filter((el) => el.time !== this.removeTime));
        console.log(ele, '???????', ele.children.filter((el) => el.time !== this.removeTime));
        this.nestRemove(ele.children);
      });
    },
    // nestCheck(arr) {
    //   const check = task.some((ele) => ele.time === thing.time);
    //   if (check) {
    //     arr.forEach((el) => {
    //       nestCheck(el.children)
    //     })
    //   } else {
    //     this.$store.state.removeIndexs.push()
    //   }
    // },
    async onChange(event) {
      console.log(event.added, '???');
      if (event.added !== undefined) {
        this.count = 0;
        this.deep = 0;
        // this.findIndexArr = await [];
        await this.nestDeep(this.$store.state.taskCategories);
        this.findTime = await event.added.element.time;
        // await this.nestFind(this.$store.state.taskCategories);
        await console.log('KKKKKK', this.findIndexArr);
        await console.log('FFFFFF', this.$store.state.taskCategories);
      }
      // console.log('???', this.$store.state.taskCategories);
    },
    async nestDeep(arr) {
      console.log('fire nemememnnmenejhgj', arr);
      this.$set(this, 'deep', this.deep + 1);
      this.deepArray = [];
      const check = arr.some((ele) => ele.children.length > 0);
      if (check) {
        await arr.forEach((el) => {
          el.children.forEach((e) => {
            this.deepArray.push(e);
          });
        });
        await this.nestDeep(this.deepArray);
      }
      // console.log('dddd', arr)
      // if (check) {
      //   this.deepArray.push(ele)
      //   this.nestDeep(this.deepArray);
      // }
    },
    // nestFind(arr) {
    //   this.findArray = [];
    //   this.count += 1;
    //   if (this.count < this.deep * 4) {
    //     arr.forEach(async (ele, index) => {
    //       const check = ele.children.some((el) => {
    //         el.time === this.findTime
    //       });
    //       console.log(';;;;;;', check, this.findTime)
    //       if(check) {
    //         this.findIndexArr.splice(0, 0, index);
    //         this.findTime = ele.time;
    //       } else {
    //         await ele.children.forEach((el) => {
    //           this.findArray.push(el)
    //         })
    //         this.nestFind(this.findArray);
    //       }
    //     });
    //   }
    // },
    readObjInArr(array, time) {
      /* eslint-disable */
      let result = array.find((ele) => {
        console.log(ele, 'ele')
        return ele.children.some((child) => {
          console.log(child.time, time);
          return child.time === time;
        });
      });
      console.log(result, '<><><><><>');
      /* eslint-disable */
      // array.find((ele) => {
      //   return ele.children.some((child) => {
      //     return child.time === time;
      //   });
      // });
    },
  },
};
</script>
<style scoped>

</style>
